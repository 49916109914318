function toggleMobileMenu() {
	$('#header').toggleClass('mobileToggled');
}

function scrollToContent() {
	$('html, body').animate({
	    scrollTop: $('.content:visible:first').offset().top - 50
	}, 500);
}

function scrollToElement(el) {
    $('html, body').animate({
        scrollTop: $(el).offset().top
    }, 500);
}


function checkCookieAcceptance() {
    if (localStorage.getItem("cookies")) {
        $('#cookies').css("display", "none");
    }
};

 function acceptCookies() {
    $('#cookies').animate({'height': 0});
    localStorage.setItem("cookies", true);
};

$( document ).ready(function() {
    checkCookieAcceptance();

    var buttonUpShown = 0;
    var scroll = $(window).scrollTop();

    if (scroll > 500) {
        if (buttonUpShown === 0) {
            $(".back-up").addClass("show");
            buttonUpShown = 1;
        }
    } else {
        if (buttonUpShown === 1) {
            $(".back-up").removeClass("show");
            buttonUpShown = 0;
        }
    }

    $(window).scroll(function () {
        var scroll = $(window).scrollTop();

        if (scroll > 500) {
            if (buttonUpShown === 0) {
                $(".back-up").addClass("show");
                buttonUpShown = 1;
            }
        } else {
            if (buttonUpShown === 1) {
                $(".back-up").removeClass("show");
                buttonUpShown = 0;
            }
        }
    });

});